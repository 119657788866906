import React from "react";

function DashBoard() {
  const dashboardUrl = "https://stage.efarm.digitalgreen.org/dashboard";
  const fpc_name = localStorage.getItem("first_name");
  const user_id = localStorage.getItem("userId")?.slice(1, -1);
  console.log("fpc_name", fpc_name);
  console.log("user_id", user_id);
  return (
    <>
      <div>
        <iframe
          title="efarm-dashboard"
          src={`${dashboardUrl}?user_id=${user_id}`}
          width={1400}
          height={900}
          id="reports"
        ></iframe>
      </div>
    </>
  );
}

export default DashBoard;
